import { service } from '@/ajax/request'


// deviceCode	设备厂商编码	query	false	
// string
// deviceName	设备名称	query	false	
// string
// deviceTypeId	设备类型id	query	false	
// integer(int32)
// fromDate	设备创建开始时间	query	false	
// string(date-time)
// pageNum	pageNum,示例值(1)	query	false	
// integer(int32)
// pageSize	pageSize,示例值(5)	query	false	
// integer(int32)
// toDate	设备创建截止时间
export const deviceList = (params: any) => (
  service({
    url: '/device/deviceManage/list',
    method: 'POST',
    params,
    type: 'newForm'
  })
)

export const getDeviceTypeList = (params: any) => (
  service({
    url: '/device/deviceManage/getDeviceTypeList',
    method: 'GET',
    type: 'form',
    params
  })
)

export const save = (params: any) => (
  service({
    url: '/device/deviceManage/save',
    method: 'POST',
    params
  })
)

export const update = (params: any) => (
  service({
    url: '/device/deviceManage/update',
    method: 'POST',
    params
  })
)

export const updateEnableState = (params: any) => (
  service({
    url: '/device/deviceManage/updateEnableState',
    method: 'POST',
    type: 'form',
    params
  })
)

export const getIsSupportScan = (params: any) => (
  service({
    url: '/device/deviceType/getIsSupportScan',
    method: 'POST',
    params
  })
)

